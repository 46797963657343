import { Entity, Model, nullable, to, type,reverse } from "typox";

@Entity()
export class CreateOrder extends Model {
  constructor(source: Object) {
    super(source);
    this.merge(source);
  }

  @type(String)
  @to()
  phone = "";

  @type(Number)
  @to()
  productId = "";

  @type(Number)
  @to()
  platform = 1;

  @type(Number)
  @nullable()
  @to()
  channel = 1;
}



@Entity()
export class CreateOrderV2 extends Model {
  constructor(source?: Object) {
    super(source)
    this.merge(source)
  }

  @type(Number)
  @to('id')
  productId = ''

  @type(Boolean)
  @to('isUse')
   isUsePoints= true

  @type([Number, String])
  @to()
  payType = 1

  @nullable()
  @type(String)
  @to()
  phones = ''

  @nullable()
  @type([String, Number])
  @reverse((x:any)=>Number(x))
  @to('proCh')
  channel = 0
}

