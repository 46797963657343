import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/index.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/detail", // 盒子使用
    name: "Detail",
    component: () => import("@/views/Detail.vue"),
  },
  {
    path: "/history",
    name: "History",
    component: () => import("@/views/History.vue"),
  },
  {
    path: "/alipay",
    name: "Alipay",
    component: () => import("@/views/Alipay.vue"),
  },
  {
    path: "/back",
    name: "Back",
    component: () => import("@/views/AfterAlipay.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
