<template>
  <div id="app">
    <!-- <router-view/> -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          v-if="$route.meta.keepAlive"
          :is="Component"
          :key="$route.name"
        />
      </keep-alive>
      <component
        v-if="!$route.meta.keepAlive"
        :is="Component"
        :key="$route.name"
      />
    </router-view>
  </div>
</template>
<script>
// document.body.addEventListener('touchmove', (e) => {
//   e.preventDefault(); // 阻止默认的处理方式(阻止下拉滑动的效果)
// }, { passive: false }); // passive 参数不能省略，用来兼容ios和android
</script>
<style lang="less" scoped>
#app {
  height: 100vh;
}
</style>
