import { queryToken, wxJsdk } from "@/core/config";
import { loadCostumeComponent, loadVantComponent } from "@/plugins";
import "@/style/index.less";
import "amfe-flexible";
import "vant/lib/index.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);

loadVantComponent(app);
loadCostumeComponent(app);

app.use(store).use(router).mount("#app");

wxJsdk();
queryToken();
