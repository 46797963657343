import MiaoFooter from "@/components/Footer.vue";
import MiaoGrids from "@/components/grids/index.vue";
import MiaoLoading from "@/components/Loading.vue";
import MiaoTab from "@/components/Tab.vue";
import MiaoText from "@/components/Text.vue";
import MiaoGrid from "@/components/gird/index.vue";
import {
  Button,
  Cell,
  CellGroup,
  DropdownItem,
  DropdownMenu,
  Field,
  Icon,
  List,
  Loading,
  NoticeBar,
  Popup,
  Radio,
  RadioGroup,
  Search,
  Tab,
  Tabs,
  Toast,
  Badge,
} from "vant";
import { createApp } from "vue";

const components = [
  Button,
  NoticeBar,
  Field,
  CellGroup,
  DropdownMenu,
  DropdownItem,
  Cell,
  Search,
  Popup,
  Icon,
  RadioGroup,
  Radio,
  Toast,
  Loading,
  List,
  Button,
  Tab,
  Tabs,
  Badge
];
export const loadVantComponent = function (app: ReturnType<typeof createApp>) {
  components.forEach((v) => {
    app.use(v);
  });
};

export const loadCostumeComponent = function (app: ReturnType<typeof createApp>) {
  app.component("MiaoFooter", MiaoFooter);
  app.component("MiaoGrids", MiaoGrids);
  app.component("MiaoText", MiaoText);
  app.component("MiaoTab", MiaoTab);
  app.component("MiaoLoading", MiaoLoading);
  app.component("MiaoGrid", MiaoGrid);
};
