import wechatConfig from "@/config/weChat";
import SystemRepository from "@/modules/system/repository";
import wx from "weixin-jsapi";

export const wxJsdk = async () => {
  const url = encodeURIComponent(location.href.split("#")[0]);
  const res = await SystemRepository.queryJssdk(url, wechatConfig.isOrange);
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: res.data.configMap.appId, // 必填，公众号的唯一标识
    timestamp: res.data.configMap.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.configMap.nonceStr, // 必填，生成签名的随机串
    signature: res.data.configMap.signature, // 必填，签名
    jsApiList: ["chooseWXPay", "onMenuShareAppMessage"], // 必填，需要使用的JS接口列表
  });
};

function getUrlCode() {
  // 截取url中的code方法
  const url = location.search;
  let code;
  if (url.indexOf("?") !== -1) {
    const [first] = url.split("&");
    const [, isCode] = first.split("=");
    code = isCode;
  }
  return code || "";
}

export const queryToken = async () => {
  // const code = "0013KNFa1B3L7G0Qd8Ja1xSrJB33KNFd";
  const code = getUrlCode(); // 截取code

  const res: any = await SystemRepository.queryToken(code, wechatConfig.isOrange);
  if (res.code === 20000) {
    const getToken = sessionStorage.getItem("authToken");
    if (!getToken) {
      sessionStorage.setItem("authToken", JSON.stringify(res.data.token));
    }
  }
};
